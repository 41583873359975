<template>
  <router-view />
</template>

<style lang="scss">
@import './assets/styles/reset';
@import './assets/styles/fonts';
@import './assets/styles/variables';
@import './assets/styles/colors';
@import './assets/styles/main';
</style>
